import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { on } from 'dom-event'

const SUBMIT_BTN_SELECTOR = '.klaviyo-form [type="button"]'
const EMAIL_INPUT_SELECTOR = '.klaviyo-form [type="email"]'
const TERM_CHECKBOX_SELECTOR = '.klaviyo-form [type="checkbox"]'
const ERROR_MESSAGE_SELECTOR = '#bluecoreEmailCaptureError'
const ERROR_CLASS = 'error'

Vue.component('newsletter-form', {
  data() {
    return {
      email: '',
      isAcceptedTerms: false,
      hasError: false,
      checkFocus: false,
      disableButton: true
    }
  },
  computed: {
    isDisabled() {
      return isEmpty(this.email) || !this.isAcceptedTerms
    },
  },
  watch: {
    email (val) {
      const emailInputEl = this.$el.querySelector(EMAIL_INPUT_SELECTOR)
      if (!val) {
        emailInputEl.classList.add('not-text')
      } else {
        emailInputEl.classList.remove('not-text')
      }
    },
    isDisabled(val) {
      const submitButtonEl = this.$el.querySelector(SUBMIT_BTN_SELECTOR)

      if (submitButtonEl) {
        submitButtonEl.disabled = val
        this.disableButton = val
      }
    },
    hasError(val) {
      const emailInputEl = this.$el.querySelector(EMAIL_INPUT_SELECTOR)

      if (val) {
        emailInputEl.classList.add(ERROR_CLASS)
        emailInputEl.focus()
        emailInputEl.setAttribute('aria-invalid', 'true')
        emailInputEl.setAttribute(
          'aria-describedby',
          'bluecoreEmailCaptureError'
        )
      } else {
        emailInputEl.classList.remove(ERROR_CLASS)
        emailInputEl.setAttribute('aria-invalid', 'false')
        emailInputEl.removeAttribute('aria-describedby')
      }
    },
  },
  mounted() {
    this.initEvents()
    this.observeForm()
  },
  methods: {
    observeForm() {
      const config = { attributes: false, childList: true, subtree: true }
      const callback = () => {
        this.initEvents()
      }

      const observer = new MutationObserver(callback)
      observer.observe(this.$el, config)
    },
    initEvents() {
      const emailInputEl = this.$el.querySelector(EMAIL_INPUT_SELECTOR)
      const checkboxEl = this.$el.querySelector(TERM_CHECKBOX_SELECTOR)
      const submitButtonEl = this.$el.querySelector(SUBMIT_BTN_SELECTOR)
      if (emailInputEl) {
        on(
          emailInputEl,
          'input',
          debounce((e) => {
            this.email = e.target.value
          }, 250)
        )
        on(emailInputEl, 'focusin', (e) => {
          this.checkFocus = true
          emailInputEl.classList.add('not-text')
        })
        on(emailInputEl, 'focusout', (e) => {
          if (!this.email) {
            this.checkFocus = false
          }
        })
      }

      if (checkboxEl) {
        on(checkboxEl, 'change', (e) => {
          this.isAcceptedTerms = e.target.checked
        })
      }

      if (submitButtonEl) {
        on(submitButtonEl, 'click', () => {
          setTimeout(this.onSubmitClick)
        })
      }
    },
    onSubmitClick() {
      const errorMessageEl = this.$el.querySelector(ERROR_MESSAGE_SELECTOR)

      this.hasError = !!errorMessageEl
    },
  }
})